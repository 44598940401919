import { Typography } from "@mui/material";
import BusinessModelCanvas from "app/components/BusinessCanvas/BusinessCanvas";
import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const CardContainer = styled.div<{ expanded: boolean }>`
  background: linear-gradient(
      to right,
      rgba(8, 9, 57, 0.95) 0%,
      rgba(8, 9, 57, 0.6) 100%
    ),
    url("/images/assets/card_img.png"); // Ensure this path is correct
  background-size: cover;
  border-radius: 20%;
  transition: all 0.3s ease;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; // Align content to the top
  width: ${(props) => (props.expanded ? "100%" : "300px")};
  height: ${(props) => (props.expanded ? "auto" : "200px")};
  overflow: hidden;
  box-shadow: 10px 15px 4px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
`;
const CardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px; // Adjust as needed
  opacity: 0.85; // Make the title more visible against the background
`;

const CardTitle = styled.h2`
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
`;

const CardContent = styled.div<{ expanded: boolean }>`
  display: ${(props) => (props.expanded ? "block" : "none")};
  padding: 20px;
  background-color: transparent; // Makes the content's background transparent
  transition: max-height 0.3s ease;
`;

const BusinessCanvasCard: React.FC = () => {
  const [expanded, setExpanded] = useState<boolean>(false);
  let navigate = useNavigate();

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <CardContainer expanded={expanded} onClick={toggleExpand}>
      <CardHeader
        style={
          expanded
            ? { textAlign: "left", justifyContent: "left", marginLeft: "80px" }
            : {}
        }
      >
        <CardTitle>
          Business Canvas
          {expanded && (
            <Typography variant="subtitle1" color="white">
              <br />
              Take the first step towards starting your business
            </Typography>
          )}
        </CardTitle>
      </CardHeader>
      <CardContent
        expanded={expanded}
        onClick={() => navigate("/businessCanvas")}
      >
        <BusinessModelCanvas />
      </CardContent>
    </CardContainer>
  );
};

export default BusinessCanvasCard;
