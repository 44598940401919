import React, { useRef, useEffect } from "react";

interface MultilineTextFieldProps {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  style?: React.CSSProperties;
}

const MultilineTextField: React.FC<MultilineTextFieldProps> = ({
  value,
  onChange,
  placeholder,
  style,
}) => {
  const divRef = useRef<HTMLDivElement>(null);

  const handleChange = (e: React.FormEvent<HTMLDivElement>) => {
    onChange(e.currentTarget.textContent || "");
  };

  useEffect(() => {
    if (divRef.current) {
      // Place the cursor at the end of the text
      const range = document.createRange();
      const sel = window.getSelection();
      range.setStart(
        divRef.current.childNodes[0] || divRef.current,
        value.length
      );
      range.collapse(true);
      sel?.removeAllRanges();
      sel?.addRange(range);
    }
  }, [value]);

  return (
    <div
      contentEditable
      ref={divRef}
      suppressContentEditableWarning
      onInput={handleChange}
      style={{
        ...style,
        padding: "8px",
        minHeight: "50px",
        whiteSpace: "pre-wrap",
        wordWrap: "break-word",
        color: "white",
        backgroundColor: "transparent",
        outline: "none",
        direction: "ltr", // Ensure text is left-to-right
        textAlign: "left", // Ensure text is aligned to the left
      }}
    >
      {value}
    </div>
  );
};

export default MultilineTextField;
