import React from "react";
import { Typography, Box } from "@mui/material";

// Define a type for the props that you expect for your component
type CenteredTypographyProps = {
  title: string;
  setOpenSideMenu: React.Dispatch<React.SetStateAction<boolean>>;
};

const CenteredTypography: React.FC<CenteredTypographyProps> = ({
  title,
  setOpenSideMenu,
}) => {
  return (
    <Box
      sx={{
        height: "64px",
        background: "#034a6e",
        display: "flex",
        alignItems: "center",
        pl: "50px",
        fontWeight: "bolder",
      }}
    >
      <Typography sx={{ color: "white", cursor: "pointer" }} variant="h5">
        <span onClick={() => setOpenSideMenu(true)}>{title}</span>
        {/* Use the title prop here */}
      </Typography>
    </Box>
  );
};

export default CenteredTypography;
