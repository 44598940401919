import React, { useState, useEffect } from "react";
import Div from "app/components/Div/Div";
import PageTitle from "app/components/PageTitle/PageTitle";
import BusinessCanvasCard from "app/components/CardContainer/BusinessCanvas";
import EcosystemMap from "app/components/CardContainer/EcosystemMap";
import LearningMaterial from "app/components/CardContainer/LearningMaterial";
import TextToSpeechPDF from "app/components/TextToSpeech";
import CenteredTypography from "app/components/CenteredTypography/CenteredTypography";
import SideMenu from "app/components/SideMenu/SideMenu";
import Donor from "./components/donor";
import EnrootTool from "./components/enroot";
import { useAuth } from "app/Context/AuthContext";

const Home: React.FC = () => {
  const { logout } = useAuth(); // Destructure the login function from context
  const [expandedCard, setExpandedCard] = useState<string | null>(null);
  const [open, setOpen] = React.useState(false);
  const [openSideMenu, setOpenSideMenu] = React.useState(false);
  const [userRole, setUserRole] = useState<string>("");
  let token = localStorage.getItem("token");

  useEffect(() => {
    if (token) {
      fetchUserRole();
    }
  }, [token]);
  const fetchUserRole = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_API}/api/session/user`,
        {
          method: "GET", // or any other HTTP method like POST, PUT, DELETE, etc.
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          credentials: "include",
        }
      );
      if (response.ok) {
        const data = await response.json();
        setUserRole(data.role);
      } else if (response.status === 401) {
        logout(); // Call logout on 401 unauthorized
      } else {
        throw new Error("Failed to fetch user details");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  // Adjust the grid layout based on whether a card is expanded
  const gridLayout = {
    display: "grid",
    gridTemplateColumns: expandedCard ? "2fr 1fr" : "repeat(3, 1fr)",
    gridTemplateRows: expandedCard ? "1fr" : "auto",
    gap: expandedCard ? "0 20px" : "20px",
    marginTop: "20px",
  };

  const cardStyle = {
    transition: "all 0.3s ease",
    cursor: "pointer",
    display: "flex",
    justifyContent: "left",
    alignItems: "center",
  };

  const expandedCardStyle = {
    ...cardStyle,
    gridRow: "1 / 2",
    gridColumn: expandedCard ? "1 / 2" : "auto",
    zIndex: 2,
  };

  const otherCardsStyle = {
    ...cardStyle,
    gridRow: expandedCard ? "1" : "auto",
    gridColumn: expandedCard ? "2" : "auto",
  };
  const otherCardsStyle1 = {
    ...cardStyle,
    gridRow: expandedCard ? "2" : "auto",
    gridColumn: expandedCard ? "2" : "auto",
  };
  const toggleExpand = (cardId: string) => {
    setExpandedCard(expandedCard === cardId ? null : cardId);
  };

  return (
    <Div sx={openSideMenu ? { pl: "220px" } : {}}>
      {!openSideMenu && (
        <CenteredTypography title="Tools" setOpenSideMenu={setOpenSideMenu} />
      )}
      {openSideMenu && (
        <SideMenu title="Tools" setOpenSideMenu={setOpenSideMenu} />
      )}
      <TextToSpeechPDF
        file={"/files/SYB_Manual_En.pdf"}
        open={open}
        onClose={() => setOpen(false)}
      />
      <Div sx={{ m: 5 }}>
        <PageTitle title="Beneficiary Tools" />
        <Div sx={gridLayout}>
          <Div
            sx={
              expandedCard === "businessCanvas"
                ? expandedCardStyle
                : otherCardsStyle
            }
            onClick={() => toggleExpand("businessCanvas")}
          >
            <BusinessCanvasCard />
          </Div>
          <Div
            sx={
              expandedCard === "ecosystemMap"
                ? expandedCardStyle
                : expandedCard === "businessCanvas"
                ? otherCardsStyle
                : otherCardsStyle1
            }
            onClick={() => toggleExpand("ecosystemMap")}
          >
            <EcosystemMap />
          </Div>
          <Div
            sx={
              expandedCard === "learningMaterial"
                ? expandedCardStyle
                : otherCardsStyle1
            }
            onClick={() => toggleExpand("learningMaterial")}
          >
            <LearningMaterial setOpenTextToSpeach={() => setOpen(true)} />
          </Div>
        </Div>
        {/* /// */}
        {(userRole === "ADMIN" || userRole === "PARTNER") && <Donor />}
        {(userRole === "ADMIN" || userRole === "STAFF") && <EnrootTool />}
      </Div>
    </Div>
  );
};

export default Home;
