// src/App.tsx
import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter as Router } from "react-router-dom";
import Layout from "app/layout";
import AppRoutes from "app/routes";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { AuthProvider } from "./Context/AuthContext";

const queryClient = new QueryClient();

const theme = createTheme({
  palette: {
    primary: {
      main: "#044a6e",
    },
    secondary: {
      main: "#67c3a0",
    },
    text: {
      primary: "#000000",
    },
  },
  typography: {
    allVariants: {
      fontFamily: "Montserrat",
    },
  },
});
const App: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <Router>
            <Layout>
              <AppRoutes />
            </Layout>
          </Router>
        </AuthProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
};

export default App;
