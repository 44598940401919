import { create } from "zustand";

// Define the structure for the Business Model Canvas within your application
interface BusinessModelCanvas {
  BI: string; // Business Idea
  CS: string; // Customer Segment
  VP: string; // Value Proposition
  Channel: string; // Channels
  Customer_Relationships: string; // Customer Relationships
  Revenue_Streams: string; // Revenue Streams
  Key_Resources: string; // Key Resources
  Key_Activities: string; // Key Activities
  Key_Partners: string; // Key Partners
  Cost_structure: string; // Cost Structure
  Language: string; // Language
  user_defined: any;
  Sector: string;
  Subsector: string;
  Project_Name: string;
}

// Define the store with a business model canvas object and a method to update it
type Store = {
  businessModelCanvas: BusinessModelCanvas;
  updateBusinessModelCanvas: (
    updatedCanvas: Partial<BusinessModelCanvas>
  ) => void;
};

export const businessModelCanvasStore = create<Store>((set) => ({
  businessModelCanvas: {
    BI: "",
    CS: "",
    VP: "",
    Channel: "",
    Customer_Relationships: "",
    Revenue_Streams: "",
    Key_Resources: "",
    Key_Activities: "",
    Key_Partners: "",
    Cost_structure: "",
    Language: "",
    user_defined: null,
    Sector: "",
    Subsector: "",
    Project_Name: "",
  },
  updateBusinessModelCanvas: (updatedCanvas) =>
    set((state) => ({
      businessModelCanvas: {
        ...state.businessModelCanvas,
        ...updatedCanvas,
      },
    })),
}));
