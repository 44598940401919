import React, { useState, useEffect } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { useSpeechSynthesis } from "react-speech-kit";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from "@mui/material";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import PauseCircleFilledIcon from "@mui/icons-material/PauseCircleFilled";
import * as pdfjsLib from "pdfjs-dist/webpack";

// Setup for react-pdf worker
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const TextToSpeechPDF = ({ file, open, onClose }) => {
  const [numPages, setNumPages] = useState(null);
  const [textPatches, setTextPatches] = useState([]);
  const [currentPatchIndex, setCurrentPatchIndex] = useState(0);
  const { speak, cancel, speaking } = useSpeechSynthesis();

  useEffect(() => {
    extractTextFromPDF(file);
  }, [file]);
  useEffect(() => {
    if (speaking && currentPatchIndex > 0) {
      handleSpeak();
    } else {
      currentPatchIndex > 0 && cancel();
    }
    return () => {
      cancel();
    };
  }, [speaking, currentPatchIndex]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const extractTextFromPDF = async (pdfFile) => {
    let finalText = "";
    try {
      const loadingTask = pdfjsLib.getDocument(pdfFile);
      const pdf = await loadingTask.promise;

      for (let pageNum = 10; pageNum <= pdf.numPages; pageNum++) {
        const page = await pdf.getPage(pageNum);
        const textContent = await page.getTextContent();
        const texts = textContent.items.map((item) => item.str).join(" ");
        finalText += texts + " ";
      }

      // Split the text into patches here. Example: split by every 1000 characters.
      const patchSize = 1000; // Adjust patch size as needed
      const patches = finalText.match(
        new RegExp(".{1," + patchSize + "}", "g")
      );
      setTextPatches(patches || []);
    } catch (error) {
      console.error("Error extracting text from pdf:", error);
    }
  };

  const handleSpeak = () => {
    cancel();
    if (textPatches.length > 0 && currentPatchIndex < textPatches.length) {
      speak({
        text: textPatches[currentPatchIndex],
        onEnd: handleSpeakNextPatch,
      });
    }
    setTimeout(() => {
      handleSpeakNextPatch();
    }, 19000);
  };

  const handleSpeakNextPatch = () => {
    if (currentPatchIndex < textPatches.length - 1) {
      setCurrentPatchIndex(currentPatchIndex + 1);
    }
  };

  const handlePauseResume = () => {
    if (speaking) {
      cancel();
    } else {
      handleSpeak();
    }
  };

  const handleClose = () => {
    cancel(); // Stop speaking when the dialog is closed
    setCurrentPatchIndex(0); // Reset patch index
    if (onClose) onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>PDF to Speech</DialogTitle>
      <DialogContent>
        <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
          {Array.from(new Array(numPages), (el, index) => (
            <Page
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              width={600}
            />
          ))}
        </Document>
      </DialogContent>
      <DialogActions style={{ justifyContent: "space-between" }}>
        <IconButton
          onClick={handlePauseResume}
          style={{ flexGrow: 1, justifyContent: "center" }}
        >
          {speaking ? (
            <PauseCircleFilledIcon style={{ fontSize: "3rem" }} />
          ) : (
            <PlayCircleIcon style={{ fontSize: "3rem" }} />
          )}
        </IconButton>
        <Button
          onClick={handleClose}
          color="primary"
          variant="contained"
          style={{ position: "absolute", right: "8px" }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TextToSpeechPDF;
