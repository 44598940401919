import React, { useState, useEffect } from "react";
import styled from "styled-components";
import MapComponent from "../MapComponent";
import Div from "../Div/Div";
import { useNavigate } from "react-router-dom";

interface Place {
  name: string;
  lat: number;
  lng: number;
}

const CardContainer = styled.div<{ expanded: boolean }>`
  background: ${(props) =>
    props.expanded
      ? "none"
      : `linear-gradient(
    to right,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0.5) 100%
  ),
  url("/images/assets/map.png")`}; // The background is removed when expanded
  background-size: cover;
  border-radius: 20%;
  transition: all 0.3s ease;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: ${(props) => (props.expanded ? "100%" : "300px")};
  height: ${(props) => (props.expanded ? "auto" : "200px")};
  overflow: hidden;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
`;
const CardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px; // Adjust as needed
  opacity: 0.85; // Make the title more visible against the background
`;

const CardTitle = styled.h2`
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
`;

const CardContent = styled.div<{ expanded: boolean }>`
  display: ${(props) => (props.expanded ? "block" : "none")};
  background-color: transparent; // Makes the content's background transparent
  transition: max-height 0.3s ease;
`;

const EcosystemMap: React.FC = () => {
  const [expanded, setExpanded] = useState(false);
  const [userLocation, setUserLocation] = useState<{
    lat: number;
    lng: number;
  } | null>(null);
  const navigate = useNavigate();

  // useEffect(() => {
  //   // Ask for user's location
  //   if ("geolocation" in navigator) {
  //     navigator.geolocation.getCurrentPosition(
  //       (position) => {
  //         setUserLocation({
  //           lat: position.coords.latitude,
  //           lng: position.coords.longitude,
  //         });
  //       },
  //       (error) => {
  //         console.error("Error getting user location:", error.message);
  //       }
  //     );
  //   } else {
  //     console.error("Geolocation is not supported in this browser.");
  //   }
  // }, []);
  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <CardContainer expanded={expanded} onClick={toggleExpand}>
      {!expanded && (
        <CardHeader>
          <CardTitle>Ecosystem Map</CardTitle>
        </CardHeader>
      )}
      <CardContent expanded={expanded}>
        {/* {userLocation && (
          <GoogleMap userLocation={userLocation} storeLocations={places} />
        )} */}
        <Div
          sx={{ width: "834px", height: "100%" }}
          onClick={() => navigate("/map")}
        >
          <MapComponent
            storePoints={[
              {
                latitude: 30.013483074857326,
                longitude: 31.318202996235865,
                name: "Enroot Consultancy, corporate office",
              },
            ]}
          />
        </Div>
      </CardContent>
    </CardContainer>
  );
};

export default EcosystemMap;
