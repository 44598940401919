import React, { useState } from "react";
import styled from "styled-components";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import { useNavigate } from "react-router-dom";
const CardContainer = styled.div<{ expanded: boolean }>`
  background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0.5) 100%
    ),
    url("/images/assets/learning.png"); // Ensure this path is correct
  background-size: cover;
  border-radius: 20%;
  transition: all 0.3s ease;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; // Align content to the top
  width: ${(props) => (props.expanded ? "100%" : "300px")};
  height: ${(props) => (props.expanded ? "auto" : "200px")};
  overflow: hidden;
  box-shadow: 10px 15px 4px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
`;
const CardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px; // Adjust as needed
  opacity: 0.85; // Make the title more visible against the background
`;

const CardTitle = styled.h2`
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
`;

const CardContent = styled.div<{ expanded: boolean }>`
  display: ${(props) => (props.expanded ? "block" : "none")};
  background-color: transparent; // Makes the content's background transparent
  transition: max-height 0.3s ease;
  padding: 100px 0;
`;

const ListItem = styled.div`
  background-color: #539c80; // Adjust the color to match your design
  border-radius: 20px;
  padding: 10px;
  margin: 10px 0;
  color: white;
  display: flex;
  align-items: center;
  width: 375px; // Adjust the width as needed
  height: 141px;
  justify-content: space-between; // This will put space between the text and the play button
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
`;

const PlayButton = styled.div`
  border-radius: 50%;
  padding: 10px;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
`;

const ViewAllButton = styled.button`
  background-color: #c27859; // Example view all button color
  border: none;
  border-radius: 20px;
  width: 197px;
  height: 69px;
  cursor: pointer;
  text-decoration: underline;
  color: white;
  align-self: flex-end; // Center the button
  float: inline-end; // Center the button
`;
type LearningMaterialProps = {
  setOpenTextToSpeach: () => void;
};
const LearningMaterial: React.FC<LearningMaterialProps> = ({
  setOpenTextToSpeach,
}) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };
  let navigate = useNavigate();

  return (
    <CardContainer
      expanded={expanded}
      onClick={toggleExpand}
      style={expanded ? { padding: "50px" } : {}}
    >
      <CardHeader style={expanded ? { height: "0px" } : {}}>
        <CardTitle>Learning Material</CardTitle>
      </CardHeader>
      <CardContent expanded={expanded}>
        <ListItem>
          <span>Start your Business Manual</span>
          <PlayButton onClick={() => setOpenTextToSpeach()}>
            <PlayCircleIcon style={{ fontSize: "50px" }} />
          </PlayButton>
        </ListItem>
        <ListItem>
          <span>
            Update of Rapid Value Chain Assessment of Kiamaraba Coffee Sector in
            Kisii and Gehitu
          </span>
          <PlayButton onClick={() => setOpenTextToSpeach()}>
            <PlayCircleIcon style={{ fontSize: "50px" }} />
          </PlayButton>
        </ListItem>
        <ViewAllButton onClick={() => navigate("/learningMaterial")}>
          View All
        </ViewAllButton>
      </CardContent>
    </CardContainer>
  );
};

export default LearningMaterial;
