import React from "react";
import Div from "app/components/Div/Div";
import BusinessModelCanvas from "app/components/BusinessCanvas/BusinessCanvas";
import CenteredTypography from "app/components/CenteredTypography/CenteredTypography";
import { Box, Typography } from "@mui/material";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import SideMenu from "app/components/SideMenu/SideMenu";
import { businessModelCanvasStore } from "app/store/chat";
import { Cairo } from "./font";
const BI: React.FC = () => {
  const [openSideMenu, setOpenSideMenu] = React.useState(false);
  const { businessModelCanvas } = businessModelCanvasStore((state) => state);

  const generatePDF = async (lang: "en" | "ar") => {
    const user = businessModelCanvas?.user_defined || {};

    const logoUrl = "images/logo/logo.png"; // URL or path to your logo image
    const today = new Date().toLocaleDateString("en-US");
    const fileName = `BMCanvas_${user?.user_name || ""}_${today.replace(
      /\//g,
      "-"
    )}.pdf`; // Format the date to avoid issues in the filename

    // Default values
    const defaultValue = "-";
    const userName = user.user_name || defaultValue;
    const userBirthdate = user.user_birthdate || defaultValue;
    const gender = user?.user_gender || defaultValue;
    const userLocation = user.user_location || defaultValue;
    const userPhoneNumber = user.user_phone_number || defaultValue;
    const userEmail = user.user_email || defaultValue;
    const userPartners = user.user_partners || defaultValue;
    const userEmployees = user.user_employees || defaultValue;
    const userExperience = user.user_entrepreneurial_experience || defaultValue;
    const sector = businessModelCanvas.Sector || defaultValue;
    const subsector = businessModelCanvas.Subsector || defaultValue;
    const businessIdea = businessModelCanvas.Project_Name || defaultValue;

    try {
      const input = document.getElementById("businessModelCanvas");
      if (input) {
        input.style.textAlign = lang === "ar" ? "right" : "left"; // Adjust text alignment based on language
        input.style.overflowWrap = "break-word";
        input.style.letterSpacing = "normal !important";
        setTimeout(() => {
          html2canvas(input, {
            useCORS: true,
            scale: 2,
            scrollY: -window.scrollY,
            allowTaint: true,
            logging: true, // Enable logging for debugging
            onclone: (documentClone) => {
              // Ensure styles are applied correctly in the cloned document
              documentClone.querySelectorAll("textarea").forEach((element) => {
                element.style.whiteSpace = "pre-wrap";
                element.style.overflowWrap = "break-word";
                element.style.width = "100%";
                element.style.letterSpacing = "normal !important";
              });
            },
          })
            .then((canvas) => {
              const imgData = canvas.toDataURL("image/png");
              const pdf = new jsPDF({
                orientation: "p",
                unit: "mm",
                format: "a4",
              });

              // Load the logo image
              const logoImg = new Image();
              logoImg.src = logoUrl;
              logoImg.onload = () => {
                const logoWidth = 50; // Adjust the width of the logo
                const logoHeight = (logoImg.height * logoWidth) / logoImg.width;
                const pdfWidth = pdf.internal.pageSize.getWidth();

                // Add the header
                pdf.setFontSize(12);
                const headerY = 10;

                // Add the date on the left
                pdf.text(today, 10, headerY);

                // Add the logo on the right
                pdf.addImage(
                  logoImg,
                  "PNG",
                  pdfWidth - logoWidth - 10,
                  headerY - 5,
                  logoWidth,
                  logoHeight
                );
                if (lang === "ar") {
                  const arabicFontBase64 = Cairo;
                  pdf.addFileToVFS(
                    "Cairo-VariableFont_slnt,wght.ttf",
                    arabicFontBase64
                  );
                  pdf.addFont(
                    "Cairo-VariableFont_slnt,wght.ttf",
                    "IBM Plex Sans Arabic",
                    "normal"
                  );
                  pdf.setFont("IBM Plex Sans Arabic");
                  pdf.setLanguage("ar");
                }
                // Add title in the center
                pdf.setFontSize(16);
                const title =
                  lang === "ar"
                    ? "نموذج العمل التجاري"
                    : "Business Model Canvas";
                const textWidth = pdf.getTextWidth(title);
                pdf.text(title, (pdfWidth - textWidth) / 2, headerY + 10);

                // User Details Section
                const textYStart = headerY + 20 + logoHeight; // Adjust the start position of the text
                let currentY = textYStart;

                pdf.setFontSize(12);
                const textAlign: any = lang === "ar" ? { align: "right" } : {};
                const textX = lang === "ar" ? pdfWidth - 10 : 10;

                pdf.text(
                  lang === "ar"
                    ? "الملف الشخصي للمستفيد:"
                    : "Beneficiary profile:",
                  textX,
                  currentY,
                  textAlign
                );
                currentY += 10;
                pdf.text(
                  `${lang === "ar" ? "الاسم" : "Name"}: ${userName}`,
                  textX,
                  currentY,
                  textAlign
                );
                currentY += 10;
                pdf.text(
                  `${
                    lang === "ar" ? "تاريخ الميلاد" : "Date of Birth"
                  }: ${userBirthdate}`,
                  textX,
                  currentY,
                  textAlign
                );
                currentY += 10;
                pdf.text(
                  `${lang === "ar" ? "النوع" : "Gender"}: ${gender}`,
                  textX,
                  currentY,
                  textAlign
                );
                currentY += 10;
                pdf.text(
                  `${
                    lang === "ar" ? "مكان الإقامة" : "Place of Residence"
                  }: ${userLocation}`,
                  textX,
                  currentY,
                  textAlign
                );
                currentY += 10;
                pdf.text(
                  `${
                    lang === "ar" ? "رقم الهاتف" : "Phone number"
                  }: ${userPhoneNumber}`,
                  textX,
                  currentY,
                  textAlign
                );
                currentY += 10;
                pdf.text(
                  `${
                    lang === "ar" ? "البريد الإلكتروني" : "Email"
                  }: ${userEmail}`,
                  textX,
                  currentY,
                  textAlign
                );
                currentY += 10;
                pdf.text(
                  `${lang === "ar" ? "الشركاء" : "Partners"}: ${userPartners}`,
                  textX,
                  currentY,
                  textAlign
                );
                currentY += 10;
                pdf.text(
                  `${
                    lang === "ar" ? "الموظفين" : "Employees"
                  }: ${userEmployees}`,
                  textX,
                  currentY,
                  textAlign
                );
                currentY += 10;
                pdf.text(
                  `${
                    lang === "ar" ? "الخبرة" : "Experience"
                  }: ${userExperience}`,
                  textX,
                  currentY,
                  textAlign
                );

                // Additional Section
                currentY += 20;
                pdf.text(
                  lang === "ar" ? "معلومات العمل:" : "Project profile:",
                  textX,
                  currentY,
                  textAlign
                );
                currentY += 10;
                pdf.text(
                  `${
                    lang === "ar" ? "فكرة العمل:" : "Project Name:"
                  } ${businessIdea}`,
                  textX,
                  currentY,
                  textAlign
                );
                currentY += 10;
                pdf.text(
                  `${
                    lang === "ar" ? "القطاع الفرعي" : "Subsector"
                  }: ${subsector}`,
                  textX,
                  currentY,
                  textAlign
                );
                currentY += 10;
                pdf.text(
                  `${lang === "ar" ? "القطاع" : "Sector"}: ${sector}`,
                  textX,
                  currentY,
                  textAlign
                );

                // Add a new page for the business model canvas image
                pdf.addPage();
                const imgProps = pdf.getImageProperties(imgData);
                const imgWidth = pdf.internal.pageSize.getWidth();
                const imgHeight = (imgProps.height * imgWidth) / imgProps.width;
                const x = 0;
                const y = (pdf.internal.pageSize.getHeight() - imgHeight) / 2;

                pdf.addImage(imgData, "PNG", x, y, imgWidth, imgHeight);

                // Add footer text on the last page
                pdf.setFontSize(10);
                pdf.text(
                  lang === "ar"
                    ? "تم الإنشاء بواسطة Enroot Virtual Mentor"
                    : "Created by Enroot Virtual Mentor",
                  10,
                  pdf.internal.pageSize.getHeight() - 10
                );

                pdf.save(fileName);
              };

              logoImg.onerror = (error) => {
                console.error("Error loading logo image", error);
              };
            })
            .catch((error) => {
              console.error("Error generating PDF", error);
            });
          /////////////////
        }, 500);
      }
    } catch (error) {
      console.error("Error generating PDF", error);
    }
  };

  return (
    <Div
      sx={
        openSideMenu
          ? {
              pl: "220px",
              direction:
                businessModelCanvas.Language === "Arabic" ? "rtl" : "ltr",
            }
          : {
              direction:
                businessModelCanvas.Language === "Arabic" ? "rtl" : "ltr",
            }
      }
    >
      {!openSideMenu && (
        <CenteredTypography
          title={
            businessModelCanvas.Language === "Arabic"
              ? "لوحة الأعمال"
              : "Business Canvas"
          }
          setOpenSideMenu={setOpenSideMenu}
        />
      )}
      {openSideMenu && (
        <SideMenu title="Business Canvas" setOpenSideMenu={setOpenSideMenu} />
      )}
      <Box
        sx={{
          height: "248px",
          width: "100%",
          background: "rgba(217, 132, 102, 1)",
          p: 5,
          mb: "20px",
          display: "flex", // Enable flex layout
          flexDirection: "row", // Arrange children in a row
          alignItems: "center", // Center items vertically
        }}
      >
        <Box>
          {businessModelCanvas.Language === "Arabic" ? (
            <Typography variant="h4" component="h6" color="white" gutterBottom>
              القالب التجاري (Business Canvas) هو أداة أساسية للشركات الناشئة
              والمتنامية.
            </Typography>
          ) : (
            <Typography variant="h4" component="h6" color="white" gutterBottom>
              The Business Canvas is an essential tool for up &<br /> coming
              businesses
            </Typography>
          )}
          {businessModelCanvas.Language === "Arabic" ? (
            <Typography variant="subtitle1" color="white">
              يُستخدم القالب التجاري (Business Canvas) لتفصيل الأبعاد المختلفة
              لشركتك، مما يساعدك على الحصول على نظرة عامة واضحة وموجزة عن عملك
              في لمحة.
            </Typography>
          ) : (
            <Typography variant="subtitle1" color="white">
              The business canvas in used to detail the different dimensions of
              your business helping you gain a clear concise top
              <br /> view of your business at a glance.
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column", // Stack image and text vertically
            alignItems: "center", // Center items horizontally
            marginRight: "20px", // Add some space between the image and the text
            textAlign: "center",
            ml: "auto", // Push the image to the right
            cursor: "pointer", // Change the cursor to a pointer
          }}
          onClick={() =>
            generatePDF(businessModelCanvas.Language === "Arabic" ? "ar" : "en")
          }
        >
          <img
            src="/images/icon/pdf.png"
            alt="pdf_icon"
            style={{ marginBottom: "10px" }}
          />
          <Typography variant="subtitle1" color="white">
            {businessModelCanvas.Language === "Arabic"
              ? " تحميل الملف"
              : "Download PDF"}
          </Typography>
        </Box>
      </Box>
      <div
        id="businessModelCanvas"
        style={{ fontFamily: "IBM Plex Sans Arabic" }}
      >
        <BusinessModelCanvas />
      </div>
    </Div>
  );
};

export default BI;
