import React, { useState } from "react";
import Div from "app/components/Div/Div";
import CenteredTypography from "app/components/CenteredTypography/CenteredTypography";
import styled from "styled-components";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import { Grid, Typography } from "@mui/material";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import SideMenu from "app/components/SideMenu/SideMenu";
import TextToSpeechPDF from "app/components/TextToSpeech";
const ListItem = styled.div`
  background-color: #539c80;
  border-radius: 20px;
  padding: 10px;
  margin: 10px 0;
  color: white;
  display: flex;
  align-items: center;
  width: 375px;
  height: auto; // Changed to auto to accommodate expanded content
  justify-content: space-between;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer; // Add cursor pointer to indicate clickability
`;

const PlayButton = styled.div`
  border-radius: 50%;
  padding: 10px;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
`;

const ExpandedContent = styled.div`
  padding: 10px;
  color: white;
`;
const InfoRow = styled.div`
  display: flex;
  justify-content: space-between; // Distribute space evenly between the items
  width: 100%; // Ensure it spans the full width of the parent
  align-items: center;
  margin-bottom: 10px; // Add some space below this row
`;

const LearningMaterial = () => {
  const [expandedId, setExpandedId] = useState(null);
  const [openSideMenu, setOpenSideMenu] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const materials = [
    {
      id: 1,
      title: "Start your Business Manual",
      location: "Kenya",
      year: "2024",
      client: "ClientB",
      background: "rgba(3, 74, 110, 1)",
    },
    {
      id: 2,
      title: "Development of an Agri-Industrial Park in Tanzania",
      objective: "Objective details here...",
      location: "Kenya",
      year: "2024",
      client: "ClientA",
      background: "rgba(103, 195, 160, 1)",
    },
    {
      id: 3,
      title:
        "Update of Rapid Value Chain Assessment of Handmade Carpet Sector in Giza and Beheira",
      objective: "Objective details here...",
      location: "Kenya",
      year: "2024",
      client: "ClientA",
      background: "rgba(217, 132, 102, 1)",
    },
    // Add more materials as needed
  ];

  const toggleExpand = (id: any) => {
    setExpandedId(expandedId === id ? null : id); // Toggle expansion
  };

  return (
    <Div sx={openSideMenu ? { pl: "220px" } : {}}>
      {!openSideMenu && (
        <CenteredTypography
          title="LEARNING MATERIAL"
          setOpenSideMenu={setOpenSideMenu}
        />
      )}
      {openSideMenu && (
        <SideMenu title="LEARNING MATERIAL" setOpenSideMenu={setOpenSideMenu} />
      )}
      <Div sx={{ m: 5 }}>
        <TextToSpeechPDF
          file={"/files/SYB_Manual_En.pdf"}
          open={open}
          onClose={() => setOpen(false)}
        />
        <Grid container spacing={2}>
          {materials.map((material) => (
            <Grid item xs={12} md={openSideMenu ? 6 : 4} key={material.id}>
              <ListItem
                onClick={() => toggleExpand(material.id)}
                style={{
                  position: "relative",
                  background: material.background,
                }}
              >
                {expandedId === material.id && (
                  <span
                    style={{ position: "absolute", top: "-10px", right: "0px" }}
                  >
                    <BookmarkIcon style={{ fontSize: "50px" }} />
                  </span>
                )}
                {expandedId !== material.id && (
                  <>
                    <span>{material.title}</span>
                    <PlayButton onClick={() => setOpen(true)}>
                      <PlayCircleIcon style={{ fontSize: "50px" }} />
                    </PlayButton>
                  </>
                )}
                {expandedId === material.id && (
                  <ExpandedContent>
                    <h3>{material.title}</h3>
                    <InfoRow>
                      <div>
                        <strong>Location:</strong> {material.location}
                      </div>
                      <div>
                        <strong>Year:</strong> {material.year}
                      </div>
                      <div>
                        <strong>Client:</strong> {material.client}
                      </div>
                      <div>
                        <PlayButton onClick={() => setOpen(true)}>
                          <PlayCircleIcon style={{ fontSize: "50px" }} />
                        </PlayButton>
                      </div>
                    </InfoRow>
                    <Typography variant="h6" color="white">
                      OBJECTIVE
                    </Typography>
                    <Typography variant="caption" color="white">
                      The aim of the study is to provide an overview of
                      successful models in the development of agri-industrial
                      parks. The main objective is to provide recommendations to
                      the Tanzanian government in developing and establishing
                      effective agri-industrial parks in relevant sectors. The
                      model presented by the study built on identified areas of
                      strengths, weaknesses, opportunities, and threats facing
                      the government of Tanzania.
                    </Typography>
                  </ExpandedContent>
                )}
              </ListItem>
            </Grid>
          ))}
        </Grid>
      </Div>
    </Div>
  );
};

export default LearningMaterial;
