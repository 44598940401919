export async function getAccessToken(): Promise<string> {
  console.info("[TOKEN-API]-> requesting new access token from API");
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_API}/api/session/token`,
    {
      credentials: "include",
    }
  );

  if (response.ok) {
    const body = await response.json();
    localStorage.setItem("token", body.token);
    return body.token;
  } else {
    console.error(
      "failed to request an access token from api: ",
      response.statusText
    );
    throw new Error(response.statusText);
  }
}
