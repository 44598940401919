import React, { useRef, useEffect } from "react";
import mapboxgl from "mapbox-gl";
import "./MapboxMap.css"; // Ensure you have the CSS for styling the map container

mapboxgl.accessToken =
  "pk.eyJ1IjoiZW5yb290IiwiYSI6ImNsdXB0aTZyZzBqaGQya254ZDdnY2M0ZGIifQ.FWXBVy1uZSQENFFxsZB1BA"; // Replace with your Mapbox access token

// TypeScript interface for props to ensure type safety
interface MapComponentProps {
  storePoints: {
    latitude: number;
    longitude: number;
    name: string;
  }[];
}

const MapboxMap: React.FC<MapComponentProps> = ({ storePoints }) => {
  const mapContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!mapContainerRef.current) return; // Exit if ref is not yet initialized

    const map = new mapboxgl.Map({
      container: mapContainerRef.current, // Use the current ref of the container
      style: "mapbox://styles/mapbox/streets-v11", // Map style
      center: [storePoints[0].longitude, storePoints[0].latitude], // Starting position [lng, lat]
      zoom: 9, // Starting zoom level
    });

    new mapboxgl.Marker() // Explicitly set the anchor to 'bottom'
      .setLngLat([storePoints[0].longitude, storePoints[0].latitude])
      .setPopup(
        new mapboxgl.Popup().setHTML(
          `<h3>${storePoints[0].name}</h3><br/><span>2879+77W، 9، الأباجية، الخليفة، محافظة القاهرة </span>`
        )
      )
      .addTo(map);
    // Clean up on unmount
    return () => map.remove();
  }, [storePoints]); // Rerun this effect if storePoints changes

  return <div ref={mapContainerRef} className="mapContainer" />;
};

export default MapboxMap;
