// src/components/Routes.tsx
import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "app/pages/home"; // Create Home component
import Login from "app/pages/login/login";
import BI from "app/pages/BI/BI";
import LearningMaterial from "app/pages/learningMatrial";
import MapPage from "app/pages/MapPage/MapPage"; // Create MapPage component
const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/" element={<Home />} />
      <Route path="/businessCanvas" element={<BI />} />
      <Route path="/learningMaterial" element={<LearningMaterial />} />
      <Route path="/map" element={<MapPage />} />

      <Route path="*" element={<Home />} />
      {/* Add more routes as needed */}
    </Routes>
  );
};

export default AppRoutes;
