import React, { ReactNode } from "react";
import Header from "app/components/Header";
import ChatComponent from "app/components/Chat/Chat";
import Div from "app/components/Div/Div";
import { useLocation } from "react-router-dom";

interface LayoutProps {
  children: ReactNode;
}
const Layout: React.FC<LayoutProps> = ({ children }) => {
  // Styles
  const location = useLocation();
  const isLoginPage = location.pathname === "/login";
  const mainContentStyle: any = {
    height: "auto", // Assuming the footer height is 265px
    display: "flex",
    minWidth: 0,
    flex: 1,
    flexDirection: "column",
    minHeight: "calc(100vh)", // Assuming the footer height is 265px
    // Other styles for your main content
  };

  const footerStyle: any = {
    width: "100%",
    height: "265px",
    position: "relative",
    bottom: 0,
    left: 0,
  };
  return (
    <Div>
      {/* Your global layout components */}
      {!isLoginPage && <Header />}
      {!isLoginPage && <Div sx={{ height: "64px" }}></Div>}
      {!isLoginPage && <ChatComponent />}
      <main style={mainContentStyle}>
        <Div sx={!isLoginPage ? { height: "auto" } : {}}>{children}</Div>
      </main>
      {!isLoginPage && <Div sx={{ height: "64px" }}></Div>}
      {!isLoginPage && (
        <footer>
          <img
            src="/images/footer/footer.png"
            alt="Footer"
            style={footerStyle}
          />
        </footer>
      )}
    </Div>
  );
};

export default Layout;
