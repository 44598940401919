import { useAuth } from "app/Context/AuthContext";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// Define the inline styles
const pageStyles: React.CSSProperties = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around", // Changed to evenly space items
  height: "100vh",
  padding: "5%",
  backgroundImage: `linear-gradient(to bottom, #256281, #1c708b, #187f92, #218d96, #349b97, #47a08c, #5ea480, #76a774, #90a060, #a99656, #be8b57, #ce7f65)`,
};

const modalStyles: React.CSSProperties = {
  width: "300px",
  height: "350px",
  backgroundColor: "#e6f2ee",
  borderRadius: "80px",
  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "32px",
  boxSizing: "border-box",
};

// Style for the welcoming text
const welcomeTextStyle: React.CSSProperties = {
  color: "rgba(255, 255, 255, 1)",
  fontSize: "20px", // Adjust as needed
  maxWidth: "400px", // Adjust based on your layout needs
};

const LoginPage: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate(); // Hook for navigation
  const { login } = useAuth(); // Destructure the login function from context

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // Prevent default form submission behavior
    try {
      await login(email, password); // Use login function from AuthContext
      navigate("/"); // Navigate to the dashboard upon successful login
    } catch (error) {
      console.error(error);
      // Handle login error (e.g., display an error message)
    }
  };

  return (
    <div style={pageStyles}>
      <div style={welcomeTextStyle}>
        Sign into Enroot to Use our wide array of tools made especially for you
      </div>
      <div style={modalStyles}>
        <h1 style={{ fontWeight: "bold", marginBottom: "16px" }}>Sign in</h1>
        <form style={{ width: "100%" }} noValidate onSubmit={handleSubmit}>
          <input
            style={{
              width: "100%",
              padding: "10px",
              marginBottom: "16px",
              borderRadius: "8px",
              border: "1px solid #ccc",
            }}
            type="text"
            id="email"
            name="email"
            placeholder="Enter your email address"
            required
            value={email}
            onChange={handleEmailChange}
          />
          <div style={{ position: "relative", marginBottom: "24px" }}>
            <input
              style={{
                width: "100%",
                padding: "10px",
                borderRadius: "8px",
                border: "1px solid #ccc",
              }}
              type={showPassword ? "text" : "password"}
              id="password"
              name="password"
              placeholder="Enter your password"
              required
              value={password}
              onChange={handlePasswordChange}
            />
            <button
              onClick={togglePasswordVisibility}
              type="button"
              style={{
                position: "absolute",
                right: "10px",
                top: "10px",
                padding: "5px",
                background: "none",
                border: "none",
                cursor: "pointer",
              }}
            >
              {showPassword ? "Hide" : "Show"}
            </button>
          </div>
          <button
            style={{
              width: "50%",
              padding: "10px",
              borderRadius: "8px",
              float: "right",
              backgroundColor: "#779341",
              color: "white",
              border: "none",
              cursor: "pointer",
            }}
            type="submit"
          >
            Sign In
          </button>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
