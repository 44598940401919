import React, { useState } from "react";
import Div from "app/components/Div/Div";
import PageTitle from "app/components/PageTitle/PageTitle";
import styled from "styled-components";
const CardContainer = styled.div`
  background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0.5) 100%
    ),
    url("/images/assets/dashboard.jpeg"); // Ensure this path is correct
  background-size: cover;
  border-radius: 20%;
  transition: all 0.3s ease;
  cursor: pointer;
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  justify-content: flex-start; // Align content to the top
  width: 400px;
  height: 150px;
  overflow: hidden;
  box-shadow: 10px 15px 4px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
`;
const CardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px; // Adjust as needed
  opacity: 0.85; // Make the title more visible against the background
`;

const CardTitle = styled.h2`
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
`;
const EnrootTool = () => {
  return (
    <Div sx={{ mt: 5 }}>
      <PageTitle title="Enroot Tools" />
      <CardContainer>
        <CardHeader>
          <CardTitle>Knowledge Base</CardTitle>
        </CardHeader>
      </CardContainer>
    </Div>
  );
};

export default EnrootTool;
