import { useCallback, useEffect, useState } from "react";
import {
  WebChatContainer,
  WebChatInstance,
} from "@ibm-watson/assistant-web-chat-react";
import { Button, styled } from "@mui/material";
import { businessModelCanvasStore } from "app/store/chat";
import { useNavigate } from "react-router-dom";
import { getAccessToken } from "app/api/token";

const StyledButton = styled(Button)({
  position: "fixed",
  bottom: 20,
  right: 20,
  width: 50,
  height: 50,
  borderRadius: "50%",
  zIndex: 1000,
});

interface WebChatOptions {
  integrationID: string;
  region:
    | "local"
    | "dev"
    | "staging"
    | "us-south"
    | "us-east"
    | "jp-tok"
    | "au-syd"
    | "eu-gb"
    | "eu-de"
    | "kr-seo";
  serviceInstanceID: string;
  identityToken: string;
  // subscriptionID?: string; // Uncomment this line if needed
}

function ChatComponent() {
  const [instance, setInstance] = useState<WebChatInstance | null>(null);
  const [webChatOptions, setWebChatOptions] = useState<WebChatOptions | null>(
    null
  );
  const { updateBusinessModelCanvas } = businessModelCanvasStore();
  let navigate = useNavigate();

  useEffect(() => {
    getAccessToken().then((token) => {
      setWebChatOptions({
        integrationID:
          process.env.REACT_APP_WATSON_ASSISTANT_ID ||
          "dad3e177-6d4c-4ff0-88a8-8b5ad8960258",
        region: "eu-de", // Replace with the appropriate region
        serviceInstanceID:
          process.env.REACT_APP_WATSON_ASSISTANT_SERVICE_INSTANCE_ID ||
          "ab5eb01a-9c1b-44ad-9bee-5e2b02b36c01",
        identityToken: token,
        // subscriptionID: 'only on enterprise plans',
      });
    });
  }, []);

  const toggleWebChat = useCallback(() => {
    instance && instance.toggleOpen();
  }, [instance]);

  const onBeforeRender = async (instance: WebChatInstance) => {
    setInstance(instance);

    const receiveHandler = (event: any) => {
      console.log("chat", event);

      const variables =
        event?.data?.context?.skills["actions skill"]?.skill_variables;
      const BM = event?.data?.context?.skills["main skill"]?.user_defined?.BM;
      const user_defined =
        event?.data?.context?.skills["main skill"]?.user_defined;
      const Language =
        event?.data?.context?.skills["main skill"]?.user_defined?.Language;

      if (
        Language &&
        !businessModelCanvasStore.getState().businessModelCanvas.Language
      ) {
        updateBusinessModelCanvas({ Language });
      }
      if (user_defined && !variables) {
        updateBusinessModelCanvas({ user_defined });
      }
      if (variables) {
        const {
          BI,
          CS,
          VP,
          Channel,
          Customer_Relationships,
          Revenue_Streams,
          Key_Resources,
          Key_Activities,
          Key_Partners,
          Cost_structure,
          Sector,
          Subsector,
          Project_Name,
        } = variables;

        updateBusinessModelCanvas({
          BI: businessModelCanvasStore.getState().businessModelCanvas.BI || BI,
          CS: businessModelCanvasStore.getState().businessModelCanvas.CS || CS,
          VP: businessModelCanvasStore.getState().businessModelCanvas.VP || VP,
          Channel:
            businessModelCanvasStore.getState().businessModelCanvas.Channel ||
            Channel,
          Customer_Relationships:
            businessModelCanvasStore.getState().businessModelCanvas
              .Customer_Relationships || Customer_Relationships,
          Revenue_Streams:
            businessModelCanvasStore.getState().businessModelCanvas
              .Revenue_Streams || Revenue_Streams,
          Key_Resources:
            businessModelCanvasStore.getState().businessModelCanvas
              .Key_Resources || Key_Resources,
          Key_Activities:
            businessModelCanvasStore.getState().businessModelCanvas
              .Key_Activities || Key_Activities,
          Key_Partners:
            businessModelCanvasStore.getState().businessModelCanvas
              .Key_Partners || Key_Partners,
          Cost_structure:
            businessModelCanvasStore.getState().businessModelCanvas
              .Cost_structure || Cost_structure,
          Sector,
          Subsector,
          Project_Name,
          Language,
          user_defined,
        });
      }
      if (BM) {
        navigate("/businessCanvas");
      }
    };

    const identityTokenExpiredHandler = async (event: {
      identityToken: string;
    }) => {
      console.debug("[CHAT_COMPONENT] identityTokenExpired handler called");
      try {
        const token = await getAccessToken();
        event.identityToken = token;
        return event;
      } catch (error) {
        console.error("Failed to refresh identity token", error);
      }
    };

    instance.on({ type: "receive", handler: receiveHandler });

    instance.on({
      type: "identityTokenExpired",
      handler: identityTokenExpiredHandler,
    });
  };

  return (
    <>
      {instance && (
        <StyledButton type="button" onClick={toggleWebChat}></StyledButton>
      )}
      {webChatOptions && (
        <WebChatContainer
          config={webChatOptions}
          onBeforeRender={onBeforeRender}
        />
      )}
    </>
  );
}

export default ChatComponent;
