import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Span from "../Span/Span";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import { useAuth } from "app/Context/AuthContext";

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

const drawerWidth = 240;
const navItems = [
  "Home",
  "Our work",
  "Partners",
  "Company",
  "Enroot Blog",
  "Contact US",
];

export default function DrawerAppBar(props: Props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [userRole, setUserRole] = React.useState<string>("");
  const { logout } = useAuth(); // Destructure the login function from context
  let token = localStorage.getItem("token");

  React.useEffect(() => {
    if (token) {
      fetchUserRole();
    }
  }, [token]);
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const fetchUserRole = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_API}/api/session/user`,
        {
          method: "GET", // or any other HTTP method like POST, PUT, DELETE, etc.
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          credentials: "include",
        }
      );
      if (response.ok) {
        const data = await response.json();
        setUserRole(data.role);
      } else if (response.status === 401) {
        logout(); // Call logout on 401 unauthorized
      } else {
        throw new Error("Failed to fetch user details");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  let navigate = useNavigate();
  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        <img
          src="/images/logo/logo.png"
          alt="Logo"
          style={{ marginRight: "10px", width: "150px" }}
        />
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton sx={{ textAlign: "center" }}>
              <ListItemText primary={item} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box
      sx={{
        display: "flex",
        "& .MuiButton-root:hover": { background: "none", color: "#67c3a0" },
      }}
    >
      <CssBaseline />
      <AppBar component="nav" sx={{ background: "#ffffff" }}>
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <Span sx={{ display: { sm: "none" } }}>
            <img
              src="/images/logo/logo.png"
              alt="Logo"
              style={{ marginRight: "10px", width: "150px" }}
            />
          </Span>

          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerToggle}
            sx={{ display: { sm: "none" } }}
          >
            <MenuIcon sx={{ color: "#044a6e", fontSize: "40px" }} />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
          >
            <img
              src="/images/logo/logo.png"
              alt="Logo"
              style={{ marginRight: "10px", width: "150px" }}
            />
          </Typography>
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            {navItems.map((item) => (
              <Button key={item} sx={{ color: "#000000" }}>
                {item}
              </Button>
            ))}
            {userRole === "ADMIN" ||
            userRole === "USER" ||
            userRole === "STAFF" ||
            userRole === "PARTNER" ? (
              <Button
                sx={{ color: "#000000" }}
                endIcon={<LogoutIcon />}
                onClick={() => logout()}
              >
                logout
              </Button>
            ) : (
              <Button
                sx={{ color: "#000000" }}
                endIcon={<LoginIcon />}
                onClick={() => navigate("/login")}
              >
                login
              </Button>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </Box>
  );
}
