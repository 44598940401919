import CenteredTypography from "app/components/CenteredTypography/CenteredTypography";
import Div from "app/components/Div/Div";
import MapComponent from "app/components/MapComponent";
import SideMenu from "app/components/SideMenu/SideMenu";
import React from "react";

const MapPage: React.FC = () => {
  const [openSideMenu, setOpenSideMenu] = React.useState(false);
  return (
    <Div sx={openSideMenu ? { pl: "220px" } : {}}>
      {!openSideMenu && (
        <CenteredTypography
          title="Ecosystem Map"
          setOpenSideMenu={setOpenSideMenu}
        />
      )}
      {openSideMenu && (
        <SideMenu title="Ecosystem Map" setOpenSideMenu={setOpenSideMenu} />
      )}
      <MapComponent
        storePoints={[
          {
            latitude: 30.013483074857326,
            longitude: 31.318202996235865,
            name: "Enroot Consultancy, corporate office",
          },
        ]}
      />
    </Div>
  );
};

export default MapPage;
