import React from "react";
import { Grid, Card, CardContent, Typography, TextField } from "@mui/material";
import { businessModelCanvasStore } from "app/store/chat";
import Span from "../Span/Span";
import MultilineTextField from "./MultiLineTextFiled";

interface CardData {
  icon: any;
  title: string;
  description: string;
  bgColor: string;
  name?: string;
  language?: string;
}

const BusinessModelCanvas: React.FC = () => {
  const { businessModelCanvas } = businessModelCanvasStore((state) => state);
  const { updateBusinessModelCanvas } = businessModelCanvasStore();

  const [textFieldValues, setTextFieldValues] =
    React.useState<any>(businessModelCanvas);
  React.useEffect(() => {
    setTextFieldValues(businessModelCanvas);
  }, [businessModelCanvas]);

  const cardData: CardData[] = [
    {
      icon: <img src="/images/icon/person.svg" alt="" />,
      title:
        businessModelCanvas.Language === "Arabic"
          ? "الشركاء الرئيسيون"
          : "KEY PARTNERS",
      description: businessModelCanvas.Key_Partners,
      bgColor: "#248fc5",
      name: "Key_Partners",
      language: businessModelCanvas.Language,
    },
    {
      icon: <img src="/images/icon/second_card.svg" alt="" />,
      title:
        businessModelCanvas.Language === "Arabic"
          ? "الأنشطة الرئيسية"
          : "KEY ACTIVITIES",
      description: businessModelCanvas.Key_Activities,
      bgColor: "#18597a",
      name: "Key_Activities",
      language: businessModelCanvas.Language,
    },
    {
      icon: <img src="/images/icon/third.svg" alt="" />,
      title:
        businessModelCanvas.Language === "Arabic"
          ? "عرض القيمة"
          : "VALUE PROPOSITION",
      description: businessModelCanvas.VP,
      bgColor: "#18597a",
      name: "VP",
      language: businessModelCanvas.Language,
    },
    {
      icon: <img src="/images/icon/fourth.svg" alt="" />,
      title:
        businessModelCanvas.Language === "Arabic"
          ? "علاقات العملاء"
          : "CUSTOMER RELATIONSHIPS",
      description: businessModelCanvas.Customer_Relationships,
      bgColor: "#248fc5",
      name: "Customer_Relationships",
      language: businessModelCanvas.Language,
    },
    {
      icon: <img src="/images/icon/fifth.svg" alt="" />,
      title:
        businessModelCanvas.Language === "Arabic"
          ? "شرائح العملاء"
          : "CUSTOMER SEGMENTS",
      description: businessModelCanvas.CS,
      bgColor: "#18597a",
      name: "CS",
      language: businessModelCanvas.Language,
    },
    {
      icon: <img src="/images/icon/fifth.svg" alt="" />,
      title: businessModelCanvas.Language === "Arabic" ? "القنوات" : "CHANNELS",
      description: businessModelCanvas.Channel,
      bgColor: "#18597a",
      name: "Channel",
      language: businessModelCanvas.Language,
    },
    {
      icon: <img src="/images/icon/sixth.svg" alt="" />,
      title:
        businessModelCanvas.Language === "Arabic"
          ? "الموارد الرئيسية"
          : "Key Resource",
      description: businessModelCanvas.Key_Resources,
      bgColor: "#248fc5",
      name: "Key_Resources",
      language: businessModelCanvas.Language,
    },
    {
      icon: <img src="/images/icon/eight.svg" alt="" />,
      title:
        businessModelCanvas.Language === "Arabic"
          ? "تدفقات الإيرادات"
          : "REVENUE STREAMS",
      description: businessModelCanvas.Revenue_Streams,
      bgColor: "#18597a",
      name: "Revenue_Streams",
      language: businessModelCanvas.Language,
    },
    {
      icon: <img src="/images/icon/nign.svg" alt="" />,
      title:
        businessModelCanvas.Language === "Arabic"
          ? "هيكل التكاليف"
          : "COST STRUCTURE",
      description: businessModelCanvas.Cost_structure,
      bgColor: "#18597a",
      name: "Cost_structure",
      language: businessModelCanvas.Language,
    },
  ];
  const handleTextFieldChange = (name: string, newValue: string) => {
    setTextFieldValues((prevValues: any) => ({
      ...prevValues,
      [name]: newValue,
    }));
    updateBusinessModelCanvas({ ...businessModelCanvas, [name]: newValue });
  };
  return (
    <>
      {businessModelCanvas.BI && (
        <Typography
          variant="h6"
          component="h6"
          sx={{
            textAlign:
              businessModelCanvas.Language === "Arabic" ? "right" : "left",
            mb: 2,
            color: "gray",
            marginLeft: businessModelCanvas.Language === "Arabic" ? "0%" : "8%",
            marginRight:
              businessModelCanvas.Language === "Arabic" ? "8%" : "0%",
            letterSpacing: "normal",
          }}
        >
          <strong>{businessModelCanvas.Project_Name}</strong>
          <br />
          {businessModelCanvas.BI}
        </Typography>
      )}
      <Grid container spacing={2} justifyContent={"center"}>
        {/* First row */}
        <Grid item xs={12} sm={5} md={2}>
          <CardComponent
            data={cardData[0]}
            handleTextFieldChange={handleTextFieldChange}
            textFieldValues={textFieldValues}
          />
        </Grid>
        <Grid item xs={12} sm={5} md={2} container spacing={2}>
          <Grid item xs={12}>
            <CardComponent
              data={cardData[1]}
              handleTextFieldChange={handleTextFieldChange}
              textFieldValues={textFieldValues}
            />
          </Grid>
          <Grid item xs={12}>
            <CardComponent
              data={cardData[2]}
              handleTextFieldChange={handleTextFieldChange}
              textFieldValues={textFieldValues}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={5} md={2}>
          <CardComponent
            data={cardData[3]}
            handleTextFieldChange={handleTextFieldChange}
            textFieldValues={textFieldValues}
          />
        </Grid>
        <Grid item xs={12} sm={5} md={2} container spacing={2}>
          <Grid item xs={12}>
            <CardComponent
              data={cardData[4]}
              handleTextFieldChange={handleTextFieldChange}
              textFieldValues={textFieldValues}
            />
          </Grid>
          <Grid item xs={12}>
            <CardComponent
              data={cardData[5]}
              handleTextFieldChange={handleTextFieldChange}
              textFieldValues={textFieldValues}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={5} md={2}>
          <CardComponent
            data={cardData[6]}
            handleTextFieldChange={handleTextFieldChange}
            textFieldValues={textFieldValues}
          />
        </Grid>
        {/* Second row */}
        <Grid item xs={12} sm={5} md={5}>
          <CardComponent
            data={cardData[7]}
            handleTextFieldChange={handleTextFieldChange}
            textFieldValues={textFieldValues}
          />
        </Grid>
        <Grid item xs={12} sm={5} md={5}>
          <CardComponent
            data={cardData[8]}
            handleTextFieldChange={handleTextFieldChange}
            textFieldValues={textFieldValues}
          />
        </Grid>
      </Grid>
    </>
  );
};

interface CardComponentProps {
  data: CardData;
  handleTextFieldChange: any;
  textFieldValues: any;
}

const CardComponent: React.FC<CardComponentProps> = ({
  data,
  handleTextFieldChange,
  textFieldValues,
}) => {
  const [isHovered, setIsHovered] = React.useState(false);

  return (
    <Card
      sx={{
        backgroundColor: data.bgColor,
        height: "100%",
        position: "relative",
        overflow: "hidden",
        color: "white",
        letterSpacing: "normal",
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {" "}
      {/* Ensure overflow is handled */}
      <CardContent
        sx={{
          letterSpacing: "normal !important",
          display: "flex",
          flexDirection: "column",
          textAlign: data.language === "Arabic" ? "right" : "left", // Align text to the left
          height: "100%",
          pb: "20%", // Add padding at the bottom to avoid text-icon overlay
        }}
      >
        <Typography
          variant="body1"
          sx={{ mb: 1, letterSpacing: "normal !important" }}
        >
          {data.title}
        </Typography>
        {/* <Typography variant="body2" sx={{ mb: 5 }}>
        {data.description}
      </Typography> */}
        {data.name && textFieldValues[data.name] && (
          <MultilineTextField
            value={textFieldValues[data.name]}
            onChange={(value) => handleTextFieldChange(data.name, value)}
            style={{
              color: "white",
              whiteSpace: "pre-wrap",
              wordWrap: "break-word",
              letterSpacing: "normal",
            }}
          />
        )}
        {/* <TextField
          id="standard-basic"
          variant="standard"
          multiline
          onChange={(e) => handleTextFieldChange(data.name, e.target.value)}
          value={textFieldValues[data.name || ""]}
          sx={{
            "& .MuiInputBase-input": {
              color: "white",
              whiteSpace: "pre-wrap", // Ensure text wraps properly
            },
            "& .MuiInput-underline:before": {
              borderBottom: "none",
            },
            "& .MuiInput-underline:after": {
              borderBottom: "none",
            },
            "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
              borderBottom: "none",
            },
          }}
          inputProps={{
            style: {
              height: "auto",
              whiteSpace: "pre-wrap",
              wordWrap: "break-word",
            },
          }}
        /> */}
      </CardContent>
      {/* Absolute positioning for the icon */}
      {!isHovered && !textFieldValues[data?.name || ""] && (
        <Span
          sx={{
            fontSize: 40,
            color: "#616161",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -20%)",
            letterSpacing: "normal",
          }}
        >
          {data.icon}
        </Span>
      )}
    </Card>
  );
};

export default BusinessModelCanvas;
