import React from "react";
import { styled } from "@mui/system";

const Title = styled("h1")(({ theme }) => ({
  fontSize: "1.5rem",
  color: theme.palette.text.primary,
  margin: 0, // Removes default margin
}));

const Underline = styled("div")(({ theme }) => ({
  width: "calc(25%)", // Adjust the width to allow space for the bullet
  height: "2px",
  backgroundColor: theme.palette.text.primary,
  marginTop: "0.3em", // Adjust the space between the title and the underline as needed
}));

const Bullet = styled("div")(({ theme }) => ({
  width: "10px",
  height: "10px",
  backgroundColor: theme.palette.text.primary,
  borderRadius: "50%",
  display: "inline-block",
  marginLeft: "0px", // Space between the line and the bullet
  marginTop: "0.3em", // Adjust the space between the title and the bullet as needed
}));

interface BeneficiaryToolsProps {
  title: string;
}

const PageTitle: React.FC<BeneficiaryToolsProps> = ({ title }) => {
  return (
    <div>
      <Title>{title}</Title>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Underline />
        <Bullet />
      </div>
    </div>
  );
};

export default PageTitle;
