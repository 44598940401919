import React from "react";
import { List, ListItem, ListItemText, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import Div from "../Div/Div";
interface SideMenuProps {
  title: string;
  setOpenSideMenu: React.Dispatch<React.SetStateAction<boolean>>;
}
const sideMenuArr = [
  {
    title: "Business Canvas",
    link: "/businessCanvas",
  },
  {
    title: "Learning Material",
    link: "/learningMaterial",
  },
  {
    title: "Ecosystem Map",
    link: "/map",
  },
];
const SideMenu: React.FC<SideMenuProps> = ({ title, setOpenSideMenu }) => {
  return (
    <Div
      sx={{
        background: "rgba(3, 74, 110, 1)",
        height: "100vh",
        width: "220px",
        position: "fixed",
        top: "0px",
        left: "0",
        zIndex: 1,
        color: "white",
      }}
    >
      <List sx={{ color: "white", mt: "120px", textAlign: "center" }}>
        <Typography variant="h5" color="white" sx={{ cursor: "pointer" }}>
          <span onClick={() => setOpenSideMenu(false)}>{title || ""}</span>
        </Typography>
        {sideMenuArr.map((arr) => (
          <ListItem key={arr.title} component={Link} sx={{}} to={`${arr.link}`}>
            <ListItemText
              primary={arr.title}
              sx={{
                textAlign: "center",
                color: "white",
                borderRadius: "10px",
                border: "1px solid white",
                padding: "8px",
              }}
            />
          </ListItem>
        ))}
      </List>
    </Div>
  );
};

export default SideMenu;
